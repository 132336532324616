import { Injectable } from '@angular/core';
import { UrlTree, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './../../login/login.service';
import { take, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard  {
  isAuthenticated: boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.loginService.user.pipe(
      take(1),
      map(user => {
        if (!!user) {
          return true;
        }
        this.router.navigate([''])
        this.loginService.showLoginModalEvt({ isShowLoginModal: true, url: state.url });
      })
    );
  }
}
